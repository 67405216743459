import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../slice/RootReducer";
import {
  getIdCardDataSuccess,
  getIdCardDataFailure,
} from "../../slice/idCarouselSlice";
import TGIcon from "../../../../shared/tg-icon";
import { config } from "../../../../config/global";
import inspirationalStyles from "./inspiration.module.scss";
import styles from "../news-announcement/news-announcement.module.scss";
import { IdCardData, IdCarouselData } from "../../models/models";
import TGInspirationDealCarousel from "../../../../shared/tg-inspiration-carousel";
import useScreenSize from "../../../../utils/hook/useScreenSize";

const InspirationDeals = () => {
  const dispatch = useDispatch();
  const isMobileView: boolean = useScreenSize().device === "mobile";

  const idCardRes = useSelector(
    (state: RootState) => state?.osciReducer?.idCarousel.IdCardDetails
  );
  console.log("id card res :", idCardRes);
  const inspirationCarouselLoading = useSelector(
    (state: RootState) => state?.osciReducer?.idCarousel.isLoading
  );

  const DISCOVER_CONTENT_API_URL =
    config.TEAMSITE_INSPIRATION_DEALS_DISCOVER_CONTENT_API;

  useEffect(() => {
    dispatch({
      type: config.FETCH_DATA_CONSTANT,
      url: DISCOVER_CONTENT_API_URL,
      isTeamsite: true,
      successAction: getIdCardDataSuccess,
      errorAction: getIdCardDataFailure,
    });
  }, [dispatch]);

  const idCardData = idCardRes.map((x: IdCardData) => {
    return {
      ...x,
      title: x.title,
      showMoreText: x.showMoreText,
      showMoreLink: x.showMoreLink,
      description: x.description,
    };
  });
  console.log("id card data :", idCardData);
  const inspirationCarouselData = idCardRes
    .map((x: IdCardData) => {
      return x.idCarouselDetails?.map((x: IdCarouselData, index: number) => {
        return {
          ...x,
          key: index,
          image: x.image,
          fromtoTitle: x.fromtoTitle,
          locationTitle: x.locationTitle,
          classTitle: x.classTitle,
          amountText: x.amountText,
          buttonText: x.buttonText,
        };
      });
    })
    .flat();
  console.log("inspiration carousel data :", inspirationCarouselData);
  return (
    <div className={inspirationalStyles.inspirationContainer}>
      <>
        {/* Heading Starts */}
        <div
          className={`${inspirationalStyles.inspiratioinSubContainer} global-content-padding`}
        >
          {idCardData.map((x: IdCardData) => (
            <div className={inspirationalStyles.inspirationHeadingContainer}>
              <div className={inspirationalStyles.inspirationHeader}>
                <div>
                  <span className={styles.headingStyle}>{x.title}</span>
                </div>
                <div className={styles.descriptionStyle}>
                  <span className={inspirationalStyles.description}>
                    {x.description}
                  </span>
                </div>
              </div>
              <div>
                <Link
                  className="a"
                  to={x.showMoreLink}
                  target="_blank"
                  style={{ color: "var(--primary-text, #684B9B)" }}
                >
                  <span className={styles.readMoreStyle}>
                    {" "}
                    {x.showMoreText}
                  </span>
                  <TGIcon icon="arrow-right-sm" fillColor="" size="20" />
                </Link>
              </div>
            </div>
          ))}
        </div>
        {/* Heading Ends */}
        {/* Card Carousel Starts */}
        <div className={styles.carouselContainer}>
          <TGInspirationDealCarousel
            inspirationData={inspirationCarouselData}
            isLoading={inspirationCarouselLoading}
          />
        </div>
        {/* Card Carousel Ends */}
      </>
    </div>
  );
};

export default InspirationDeals;
