import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TGAdCarousel from "../../../../shared/tg-ad-carousel";
import { RootState } from "../../slice/RootReducer";
import { AdCardData, AdCarouselData } from "../../models/models";
import {
  getAdCardDataSuccess,
  getAdCardDataFailure,
  getAdCarouselDataFailure,
  getAdCarouselDataSuccess,
} from "../../slice/adCarouselSlice";
import TGIcon from "../../../../shared/tg-icon";
import { SawasdeeCard } from "../sawasdee";
import styles from "./lifestyle.module.scss";
import { config } from "../../../../config/global";

const LifeStyle = () => {
  const dispatch = useDispatch();

  const adCarouselRes = useSelector(
    (state: RootState) => state?.osciReducer?.adCarousel.AdCarouselDetails
  );
  const adCardRes = useSelector(
    (state: RootState) => state?.osciReducer?.adCarousel.AdCardDetails
  );

  const adCarouselError = useSelector(
    (state: RootState) => state?.osciReducer?.adCarousel.errorCarousel
  );

  const WORLDAWAITS_API_URL = config.TEAMSITE_LIFESTYLE_WORLDAWAITS_API;
  const DESTINATION_CONTENT_API_URL =
    config.TEAMSITE_LIFESTYLE_DESTINATION_CONTENT_API;

  useEffect(() => {
    dispatch({
      type: config.FETCH_DATA_CONSTANT,
      url: WORLDAWAITS_API_URL,
      isTeamsite: true,
      successAction: getAdCardDataSuccess,
      errorAction: getAdCardDataFailure,
    });
    dispatch({
      type: config.FETCH_DATA_CONSTANT,
      url: DESTINATION_CONTENT_API_URL,
      isTeamsite: true,
      successAction: getAdCarouselDataSuccess,
      errorAction: getAdCarouselDataFailure,
    });
  }, [dispatch]);

  const adCarouselData = adCarouselRes.map((x: AdCarouselData) => {
    return {
      ...x,
      image: x.image,
      location: x.location,
      title: x.title,
    };
  });

  const adCardData = adCardRes.map((x: AdCardData) => {
    return {
      ...x,
      title: x.title,
      description: x.description,
      buttonText: x.buttonText,
      buttonUrl: x.buttonUrl,
    };
  });

  return (
    <div>
      <div
        className={styles.adCardContainer}
        style={{ height: adCarouselError ? "45rem" : "21rem" }}
      >
        {adCardData.map((x: AdCardData, index: number) => (
          <div className={styles.adCardContent} key={index}>
            <div className={styles.adCardBody}>
              <span className={styles.adCardTitle} role="heading">
                {x.title}
              </span>
              <span className={styles.adCardDescription}>{x.description}</span>
            </div>
            <div className={styles.adCardLinkContainer}>
              <Link
                className={styles.adCardLink}
                to={x.buttonUrl}
                target="_blank"
                aria-label="read more"
              >
                {x.buttonText}
              </Link>
              <TGIcon icon="arrow-right-sm" fillColor="" size="20" />
            </div>
          </div>
        ))}
      </div>
      <div className={styles.sawasdee}>
        <SawasdeeCard />
      </div>
      {!adCarouselError && (
        <div className={styles.adCarouselContainer}>
          <TGAdCarousel adData={adCarouselData} />
        </div>
      )}
    </div>
  );
};

export default LifeStyle;
